import { Scroller, ScrollerButton, ScrollerProvider } from '@graphcommerce/framer-scroller'
import { Image } from '@graphcommerce/image'
import { CategoryChildrenFragment } from '@graphcommerce/magento-category/components/CategoryChildren/CategoryChildren.gql'
import { ProductListParams } from '@graphcommerce/magento-product'
import { productListLink } from '@graphcommerce/magento-product/hooks/useProductListLink'
import {
  IconSvg,
  extendableComponent,
  iconChevronLeft,
  iconChevronRight,
  responsiveVal,
} from '@graphcommerce/next-ui'
import { Box, ButtonBase, Link, Typography, darken, styled } from '@mui/material'
import { m } from 'framer-motion'
import svgLogo from '../Layout/vaessen.svg'

type CategoryChildrenProps = Omit<CategoryChildrenFragment, 'uid'> & {
  params: ProductListParams
}

const name = 'CategoryChildren' as const
const parts = ['container', 'scroller', 'link'] as const
const { classes } = extendableComponent(name, parts)
const MotionDiv = styled(m.div)({})

export function CategoryChildren(props: CategoryChildrenProps) {
  const { children, params } = props
  const { currentPage, ...paramsWithoutCurrentPage } = params

  if (!children?.length) return null

  return (
    <Box
      sx={(theme) => ({
        maxWidth: theme.breakpoints.values.xl,
        margin: '0 auto',
        gridColumnGap: theme.spacings.sm,
        gridRowGap: theme.spacings.sm,
        px: { xs: 0, sm: '40px', md: '60px', lg: '120px' },
        pb: theme.spacings.sm,
      })}
    >
      <ScrollerProvider scrollSnapAlign='end'>
        <MotionDiv
          sx={{
            position: 'relative',
            minWidth: 1,
            '& .ArrowButtonWrap': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: `calc(50% - 42px)`,
              width: 42,
              height: 42,
              zIndex: 2,
            },
          }}
        >
          <Scroller
            className={classes.scroller}
            hideScrollbar
            sx={{
              gridAutoColumns: `max-content`,
              gridTemplateRows: '100%',
              columnGap: responsiveVal(12, 36),
              height: 'auto',
              justifyContent: {
                xs: 'flex-start',
                md: children.length > 5 ? 'flex-start' : 'center',
                lg: children.length > 7 ? 'flex-start' : 'center',
              },
            }}
          >
            {children.map((cat) => {
              if (!cat?.url_path || !cat.name || !cat.include_in_menu) return null

              return (
                <ButtonBase
                  key={cat.url_path}
                  component={Link}
                  href={productListLink({
                    ...paramsWithoutCurrentPage,
                    url: cat.url_path,
                    filters: { category_uid: { eq: cat.uid } },
                  })}
                  className={classes.link}
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacings.xxs,
                    textAlign: 'center',
                    width: '100%',
                  })}
                >
                  <Image
                    src={cat?.thumbnail ? cat.thumbnail : svgLogo}
                    layout='fixed'
                    sizes='180px'
                    width={180}
                    height={180}
                    sx={{
                      width: `clamp(120px, calc(120px + (60 * (100vw - 325px)) / 875), 180px)`,
                      height: 'auto',
                      borderRadius: '9999px',
                      objectFit: cat?.thumbnail ? 'contain' : 'none',
                      display: 'block',
                    }}
                  />
                  <Typography variant='h6'>{cat.name}</Typography>
                </ButtonBase>
              )
            })}
          </Scroller>
          <MotionDiv
            layout
            className='ArrowButtonWrap'
            sx={(theme) => ({
              left: 0,
              [theme.breakpoints.up('md')]: {
                left: -21,
              },
            })}
          >
            <ScrollerButton
              direction='left'
              sx={(theme) => ({
                backgroundColor: theme.palette.background.paper,
                '&:hover': {
                  background: darken(theme.palette.background.paper, 0.2),
                },
                [theme.breakpoints.down('md')]: {
                  display: 'flex',
                },
              })}
            >
              <IconSvg src={iconChevronLeft} />
            </ScrollerButton>
          </MotionDiv>
          <MotionDiv
            layout
            className='ArrowButtonWrap'
            sx={(theme) => ({
              right: 0,
              [theme.breakpoints.up('md')]: {
                right: -21,
              },
            })}
          >
            <ScrollerButton
              direction='right'
              sx={(theme) => ({
                backgroundColor: theme.palette.background.paper,
                '&:hover': {
                  background: darken(theme.palette.background.paper, 0.2),
                },
                [theme.breakpoints.down('md')]: {
                  display: 'flex',
                },
              })}
            >
              <IconSvg src={iconChevronRight} />
            </ScrollerButton>
          </MotionDiv>
        </MotionDiv>
      </ScrollerProvider>
    </Box>
  )
}
